@import './variables.scss';
@import './responsive.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: inherit;
}

.menuContainer {
	width: 100px;
	padding: 3px 10px 0px 0px;
	border-right: 2px solid $soft-bg;

	@include lg {
		width: max-content;
	}
}
