.addedFile {
	display: flex;
	justify-content: space-between;
}

.uploadButton {
	border-radius: 0;
	background-color: #000;

	&:hover {
		background-color: #fff;
		color: #000;
	}
}

.trashIcon {
	cursor: pointer;
	transition: all 0.1s ease;

	&:hover {
		scale: 1.15;
		color: orangered;
	}
}