.dashboard-container {
    background-color: #f8f9fa;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.chart-container {
    width: 100%;
    height: 500px; /* Increase the height of the chart */
}

.chart-canvas {
    width: 100%;
    height: 100%;
}

.back-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.back-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
}
