/* Sayfanın temel stil ayarları */
.phone-verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
  }
  
  /* Card bileşeni için stil ayarları */
  .card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Başlık için stil ayarları */
  .card h2 {
    margin-bottom: 20px;
  }
  
  /* Input alanı için stil ayarları */
  .input-field {
    width: calc(100% - 20px);
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
  /* Buton için stil ayarları */
  .submit-button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  