.page-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  
  .register-container {
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
    padding: min(1rem, 2rem);
    width: max(27vw, 18rem);
    border-radius: 1rem;
  }
  
  .text-register {
    margin-bottom: 1.5rem;
  }
  
  .buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .btn-register {
    background-color: #000;
    padding: 0.5rem 1rem;
    border: none;
    &:hover {
      background-color: #393b3b;
    }
  }
  
  .register-btn-container {
    display: flex;
    justify-content: end;
  }
  
  
  .register-form-input {
    width: max(25.3vw, 15.9rem);
    margin-top: 0.5rem;
    border: #cfd5db;
    padding: 0.3rem;
    border-radius: 0.3rem;
  }
  
  .password-container {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .show-password {
    position: absolute;
    cursor: pointer;
    right: 0.5rem;
    top: 0.8rem;
  }
  