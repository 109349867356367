.page-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-top: 100px;
}
 
.login-signup-container {
  display: flex;
  flex-direction: column;
  width: clamp(300px, 50%, 500px);
  padding: 1rem;
}
 
.action-container {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}
 
.action {
  width: 50%;
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: 1.1rem;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: #000;
  }
}
 
.login.active,
.signup.active {
  border-bottom: 1px solid #000;
}
 
.action-title {
  margin-bottom: 1rem;
}
 
.action-desc {
  margin-bottom: 2rem;
}
 
.form {
  display: flex;
  flex-direction: column;
}
 
.group-flex {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 1rem;
}
 
.form-group {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1rem;
}
 
.action-input {
  padding: 0.6rem 0.5rem;
  border: 1px solid #000;
  border-radius: 0;
  &:focus {
    border-color: #1a73e8;
  }
}
 
.action-button-group-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
}
 
.action-button {
  transition: all 0.2s ease-in;
  padding: 0.6rem;
  border: 1px solid #000;
  font-weight: 500;
  cursor: pointer;
}
 
.direct-signup {
  background-color: #000;
  color: #fff;
 
  &:hover {
    background-color: #fff;
    color: #000;
  }
}
 
.direct-login:disabled,
.direct-signup:disabled {
  background-color: #333333a8;
  border: none;
 
  &:hover {
    background-color: #333333a8;
    color: #fff;
  }
}
 
.google-signup,
.google-login {
  background-color: #fff;
  color: #000;
}
 
/* LOGIN */
.direct-login {
  background-color: #000;
  color: #fff;
 
  &:hover {
    background-color: #fff;
    color: #000;
  }
}
 
.show-password {
  position: absolute;
  cursor: pointer;
  right: 0.5rem;
  top: 2.2rem;
}
 
.password-rule-list {
  display: flex;
  gap: 5px;
  list-style: none;
  padding: 0;
  justify-content: space-between;
}
 
.ruleIcon {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
}
 
.ruleLi {
  display: flex;
  align-items: center;
  gap: 2px;
}
/* --- */
 
/* PASSWORD RULES  */
 
.password-rule-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0;
}