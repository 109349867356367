.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.login-container {
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  padding: min(1rem, 2rem);
  width: max(27vw, 18rem);
  border-radius: 1rem;
}

.text-login {
  margin-bottom: 1.5rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.btn-login {
  background-color: #000;
  padding: 0.5rem 1rem;
  border: none;
  &:hover {
    background-color: #393b3b;
  }
}

.login-btn-container {
  display: flex;
  justify-content: end;
}

.autodesk-logo {
  width: 8rem;
}

.adminLoginContent,
.apsLoginContent {
  padding: 0;
}

.login-form-input {
  width: max(25.3vw, 15.9rem);
  margin-top: 0.5rem;
  border: #cfd5db;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.password-container {
  position: relative;
  margin-bottom: 1rem;
}

.microsoft-login-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px 20px;
  background-color: #ffffff; /* Beyaz arka plan */
  color: #333333; /* Yazı rengi */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.microsoft-login-button:hover {
  background-color: #f2f2f2; /* Hover olduğunda arka plan rengi */
}

.microsoft-login-button img {
  width: 24px;
  margin-right: 10px;
}

.microsoft-login-button span {
  margin-right: 5px;
}

.google-login-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px 20px;
  background-color: #ffffff; /* Beyaz arka plan */
  color: #333333; /* Yazı rengi */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.google-login-button:hover {
  background-color: #f2f2f2; /* Hover olduğunda arka plan rengi */
}

.google-login-button img {
  width: 24px;
  margin-right: 10px;
}

.google-login-button span {
  margin-right: 5px;
}

.forgot-password-link:hover{
  background-color: #f2f2f2;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
