html{
	overflow-x: hidden;
}

::-webkit-scrollbar-button{
	width: 5px;
}

.li-dropdown div {
	display: none;
}

.li-dropdown:hover div {
	display: block;
}

.header {
	position: sticky;
	top: 0;
	z-index: 150;
}

.login-title {
	text-align: center;
	margin-bottom: 1em;
	font-size: 24px;
	color: rgb(34, 34, 34);
	font-weight: 800;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
	font-family: 'General Sans', sans-serif;
}

body {
	background: #ffffff;
	/* height: 100dvh; */
	/* width: 100dvw; */
	overflow-x: hidden;
	width: auto;
	height: auto;
}

p,
h1,
h2,
h3,
h4 {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
	font-style: italic;
}

nav {
	background: #171c24;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	height: 70px;
	padding: 0 60px; /* Daha az padding */
}

nav .logo {
	color: #fff;
	font-size: 30px;
	font-weight: 600;
	letter-spacing: -1px;
}

nav .nav-items {
	display: flex;
	flex: 1;
	padding: 0 0 0 20px; /* Daha az padding */
}

nav .nav-items li {
	list-style: none;
	padding: 0 10px; /* Daha az padding */
}

nav .nav-items li a {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	text-decoration: none;
}
nav .nav-items li a:hover {
	color: #ff3d00;
}
nav form {
	display: flex;
	height: 40px;
	padding: 2px;
	background: #1e232b;
	min-width: 18% !important;
	border-radius: 2px;
	border: 1px solid rgba(155, 155, 155, 0.2);
}
nav form .search-data {
	width: 100%;
	height: 100%;
	padding: 0 10px;
	color: #fff;
	font-size: 17px;
	border: none;
	font-weight: 500;
	background: none;
}
nav form button {
	padding: 0 15px;
	color: #fff;
	font-size: 17px;
	background: #ff3d00;
	border: none;
	border-radius: 2px;
	cursor: pointer;
}
nav form button:hover {
	background: #e63600;
}
nav .menu-icon,
nav .cancel-icon,
nav .search-icon {
	width: 40px;
	text-align: center;
	margin: 0 50px;
	font-size: 18px;
	color: #fff;
	cursor: pointer;
	display: none;
}
nav .menu-icon span,
nav .cancel-icon,
nav .search-icon {
	display: none;
}

@media (max-width: 1245px) {
	nav {
		padding: 0 50px;
	}
}
@media (max-width: 1140px) {
	nav {
		padding: 0px;
	}
	nav .logo {
		flex: 2;
		text-align: center;
	}
	nav .nav-items {
		position: fixed;
		z-index: 99;
		top: 70px;
		width: 100%;
		left: -100%;
		height: 100%;
		padding: 10px 50px 0 50px;
		text-align: center;
		background: #14181f;
		display: inline-block;
		transition: left 0.3s ease;
	}
	nav .nav-items.active {
		left: 0px;
	}
	nav .nav-items li {
		line-height: 40px;
		margin: 30px 0;
	}
	nav .nav-items li a {
		font-size: 20px;
	}
	nav form {
		position: absolute;
		top: 80px;
		right: 50px;
		opacity: 0;
		pointer-events: none;
		transition: top 0.3s ease, opacity 0.1s ease;
	}
	nav form.active {
		top: 95px;
		opacity: 1;
		pointer-events: auto;
	}
	nav form:before {
		position: absolute;
		content: '';
		top: -13px;
		right: 0px;
		width: 0;
		height: 0;
		z-index: -1;
		border: 10px solid transparent;
		border-bottom-color: #1e232b;
		margin: -20px 0 0;
	}
	nav form:after {
		position: absolute;
		content: '';
		height: 60px;
		padding: 2px;
		background: #1e232b;
		border-radius: 2px;
		min-width: calc(100% + 20px);
		z-index: -2;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	nav .menu-icon {
		display: block;
	}
	nav .search-icon,
	nav .menu-icon span {
		display: block;
	}
	nav .menu-icon span.hide,
	nav .search-icon.hide {
		display: none;
	}
	nav .cancel-icon.show {
		display: block;
	}
}
.content {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
}
.content header {
	font-size: 30px;
	font-weight: 700;
}
.content .text {
	font-size: 30px;
	font-weight: 700;
}
.space {
	margin: 10px 0;
}
nav .logo.space {
	color: red;
	padding: 0 5px 0 0;
}
@media (max-width: 980px) {
	nav .menu-icon,
	nav .cancel-icon,
	nav .search-icon {
		margin: 0 20px;
	}
	nav form {
		right: 30px;
	}
}
@media (max-width: 350px) {
	nav .menu-icon,
	nav .cancel-icon,
	nav .search-icon {
		margin: 0 10px;
		font-size: 16px;
	}
}

@media screen and (min-width: 600px) {
	.wrapper {
		grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
	}

	.card {
		flex-direction: column;
		text-align: center;
		min-width: 14rem;

		&__img {
			width: 100%;
			height: 12rem;
		}
	}
}

.dropdownItem {
	color: #14181f;
}
