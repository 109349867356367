.onedrive-upload {
	display: flex;
	padding: 1rem;
	flex-direction: column;
}

.onedrive-upload-btn,
.dnd-cancel-btn,
.dnd-upload-btn {
	background-color: black;
	color: white;
	padding: 0.4rem 1.4rem;
	border-radius: 0.5rem;
	transition: all 0.2s linear;
	&:disabled {
		background-color: #ccc;
		color: #666;
	}
}

.onedrive-upload-btn {
	align-self: start;
	&:hover {
		color: black;
		background-color: #8d8dd5;
	}
}

.dnd-cancel-btn {
	justify-self: start;
	background-color: #bd132d;
}
.dnd-upload-btn {
	&:hover {
		color: black;
		background-color: #8d8dd5;
	}
}

.dnd-modal {
	position: fixed;
	z-index: 1300;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 1);
	animation: fadeIn 0.3s ease-in-out;
}

.MuiDialog-root {
	z-index: 1400 !important;
}

.dnd-container {
	background: white;
	padding: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: min(75%, 600px);
	max-height: 90vh; 
	overflow-y: auto; 
	animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-1rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.dnd-label {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;
}

.drag-drop-area {
	text-align: center;
	border: 2px dashed #ccc;
	border-radius: 1rem;
	padding: 4rem;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 1.1rem;
	transition: border 0.3s ease-in-out;
}

.svg-container {
	width: 100%;
	display: flex;
	place-content: center;
}

.svg-icon {
	width: max(30%, 300px);
	transition: all 0.2s ease-out;
}

.dnd-desc {
	display: flex;
	flex-direction: column;
}

.dnd-upload-btn-grp {
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
}

.dnd-finished-btn-grp {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem; 
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.close-icon {
    width: 24px;
    height: 24px;
    fill: #000;
} 

.addedFile {
	display: flex;
	align-items: center;
	width: 100%;
}

.zip-file-item {
	max-height: 60vh; 
	overflow-y: auto; 
	width: 100%;
}

.zip-file-item ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.zip-file-item li {
	padding: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.zip-file-item li label {
	display: flex;
	align-items: center;
}

.progressStep{
	background-color: #f0f0f0; 
	padding: 5px;
	border-radius: 5px;
	white-space: pre-wrap;
}
