.layout-grid {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  place-content: center;
  gap: 1.2rem;
}

.loading-bucket,
.loading-object-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
