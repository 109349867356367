.object-list {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.object-list-card__img {
	max-height: 10rem;
	background-color: #f2eeed;
	object-fit: contain;
	cursor: pointer;
}

.object-list-card__title {
	font-size: 1.2rem;
	width: 100%;
	font-size: 1rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.object-list-card__description {
	line-height: 1.2rem;
}

.object-list-upload-btn {
	background-color: black;
	align-self: flex-start;
	color: white;
	padding: 0.4rem 1.4rem;
	border-radius: 0.5rem;
	transition: all 0.2s linear;
	&:hover {
		color: black;
		background-color: #8d8dd5;
	}
}

.back-button-wrapper {
	position: fixed;
	bottom: 20px;
	left: 20px;
}

