/* HTML: <div class="loader"></div> */
.loading {
	width: 50px;
	aspect-ratio: 1;
	display: grid;
	color: rgb(11, 104, 215);
	background: radial-gradient(
		farthest-side,
		currentColor calc(100% - 6px),
		#0000 calc(100% - 5px) 0
	);
	-webkit-mask: radial-gradient(
		farthest-side,
		#0000 calc(100% - 13px),
		#000 calc(100% - 12px)
	);
	border-radius: 50%;
	animation: l19 2s infinite linear;
}
.loading::before,
.loading::after {
	content: '';
	grid-area: 1/1;
	background: linear-gradient(currentColor 0 0) center,
		linear-gradient(currentColor 0 0) center;
	background-size: 100% 10px, 10px 100%;
	background-repeat: no-repeat;
}
.loading::after {
	transform: rotate(45deg);
}

@keyframes l19 {
	100% {
		transform: rotate(1turn);
	}
}

.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 17.3vh;
	background-color: rgb(242, 238, 237);
}
